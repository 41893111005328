import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { fetchDescriptionGenerator } from '../../api/apiAddressSearch';
import api, { fetchToken, strapiImage } from '../../api';
import TextField from '@material-ui/core/TextField';

const defaultPrompt = `Un des utilisateurs vient de poster cette image pour signaler une décharge sauvage.
Analyse l'image pour me dire quel type de déchets ont été abandonné sans mentionner le fait que tu analyse l'image. Et sans dire qu'il sagit d'une description.
Si possible me dire dans quel contexte ils ont été abandoné, si difficile d'être précis ignorer la demande de contexte.
Si possible me donner une estimation grossiere des quantités en kilogramme (1 Kg, 10Kg, 100Kg, 1 tonne) par types de déchets et le total, si impossible ignorer la demande d'estimations des poids des déchets et ne pas le mentioner

Ajoute moi un tableau JSON avec les différentes catégories de déchets et poids si possible.`;

export const DescriptionGeneratorModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const { record, user } = props;

  const images = record["images"];
  const [userPrompt, setUserPrompt] = useState(defaultPrompt);
  const [userPromptResponse, setUserPromptResponse] = useState('');
  const [userPromptResponseJson, setUserPromptResponseJson] = useState({})

  const handleClickOpen = () => {
    setOpen(true);
    console.log(record, user);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    console.log('test');
    if (userPromptResponse) {
      const str = userPromptResponse;
      const regStart = '```json';
      const regStop = `}
\`\`\``;

      console.log('regstart:', regStart);
      console.log('regstop:', regStop);
      console.log('indexOf(regStart):', str.indexOf(regStart))
      console.log('indexOf(regStop):', str.indexOf(regStop))


      if (str.indexOf(regStart) < 1 || str.indexOf(regStop) < 1) {
        return;
      }
      console.log(str.substring(str.indexOf(regStart)+regStart.length, str.indexOf(regStop)));
      const rawJson = str.substring(str.indexOf(regStart)+regStart.length, str.indexOf(regStop)+1);
      console.log('JSON.parse:',JSON.parse(rawJson));
      try {
        setUserPromptResponseJson(JSON.parse(rawJson));
      } catch (e) {
        console.log('not valid JSON')
      }
    }
  }, [userPrompt, userPromptResponse])

  const handleFetchGenerator = async () => {
    
    const imgUrls = (images?.length > 0)?images.map(i => {return strapiImage(i, '+').url}):[];
      if (imgUrls?.length > 0) {
          const request = await fetchToken(api.url+"/description-generator/prompt",{
            method: "POST",
            body:JSON.stringify({
              imgUrls:imgUrls,
              prompt: userPrompt
            })
        });
        const result = request?.json?.response?.candidates[0]?.content?.parts[0]?.text || "request error"
        setUserPromptResponse(result);

        console.log("return from handleFetchGenerator", request);
      } else {
        console.log("no image")
      }
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Test Générateur de description
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth={"lg"} fullWidth={true}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Test pour génération de description automatisé
        </DialogTitle>
        <DialogContent>

          <TextField 
          label="Le prompt à peaufiner pour avoir des réponses standardisé qui colle au mieux à nos attentes"
          multiline
          value={userPrompt}
          onChange={(e)=>{setUserPrompt(e.target.value)}}
          variant="outlined"
          style={{width:"100%"}}
        />
          <Typography gutterBottom style={{marginTop: 10, marginBottom: 10}}>
            <Button color="primary" onClick={handleFetchGenerator} variant="outlined">Générer description</Button>
          </Typography>

          <TextField
          label="Réponse"
          multiline
          value={userPromptResponse}
          // onChange={(e)=>{setUserPrompt(e.target.value)}}
          variant="outlined"
          style={{width:"100%"}}
        />
        </DialogContent>
        <DialogContent>
          <pre>{JSON.stringify(userPromptResponseJson, undefined, 2)}</pre>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);