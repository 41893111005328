import React, { useState } from "react";
import { Form } from 'react-final-form';
import * as yup from 'yup'
import { TextField, Radios } from 'mui-rff';

import MuiAlert from "@material-ui/lab/Alert";

import { Link, Button, Grid, Typography, CircularProgress, Box, Paper, InputAdornment, Divider, FormLabel } from "@material-ui/core";

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';


import { AuthLayout, authStyle, authValidate } from "../../Layout/AuthLayout";
import api, { authProvider } from "../../../api";

import {Checkmark} from "../../UI/Decorator/Decorator"
import { ZoneSelector } from "../../UI/ZoneSelector";

export default function Register() {
  const classes = authStyle();
  const [togglePassword, setTogglePassword] = useState(true);
  const [togglePasswordConfirm, setTogglePasswordConfirm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [warning, setWarning] = useState(false);
  const [type, setType] = useState("");
  //const [type, setType] = useState((api?.site === "LAPADE")?"Collectivité":"Association");
  const [zone, setZone] = useState({});

  const validationSchema = yup.object({
    name: yup.string().required("Votre nom est requis."),
    firstname: yup.string().required("Votre prénom est requis."),
  
    collectivite: yup.string().required(`Le nom de votre ${type} est requis.`),
  
    // eslint-disable-next-line
    // username: yup.string().min(4,"Trop court, minimum ${min} charactères").required("Requis"),
    email: yup.string().email("Veuillez saisir une adresse email valide.").required("Requis"),
  
    // eslint-disable-next-line
    password: yup.string().min(8, "Trop court, minimum ${min} charactères").required("Veuillez saisir un mot de passe."),
    passwordConfirm: yup.string().oneOf([yup.ref('password'), null], 'Les deux mots de passe ne sont pas identiques.').required("Requis")
  });

  const formFields = [
    {
      size: { xs: 12, sm: 6},
      field: (<TextField label={"Nom de votre "+type} name="collectivite" margin="none" variant="outlined" required={true} />)
    },
    {
      field: (<Divider style={{marginTop:10, marginBottom:10}}></Divider>)
    },
    {
      field: (<Radios label="Civilité" name="gender" formControlProps={{ margin: 'none' }} radioGroupProps={{ row: true }} 
          data={[
            { label: 'Madame', value: 'Madame' },
            { label: 'Monsieur', value: 'Monsieur' }
            // { label: 'Autre', value: 'Autre' },
          ]}
        />
      ),
    },
    {
      size: { xs: 12, sm: 6},
      field: (<TextField label="Prénom" name="firstname" margin="none" variant="outlined" required={true} />)
    },
    {
      size: { xs: 12, sm: 6 },
      field: (<TextField label="Nom" name="name" margin="none" variant="outlined" required={true} />)
    },
    {
      size: { xs: 12, sm: 6 },
      field: (
        (type==="Association")?
          <></>:
          <TextField label="Fonction" name="fonction" margin="none" variant="outlined" />
        )
    },
    {
      field: (<Divider style={{marginTop:10, marginBottom:10}}></Divider>)
    },
    {
      field: (<FormLabel >Identifiants</FormLabel>),
    },
    {
      size: { xs: 12, sm: 6 },
      field: (<TextField label="Email" name="email" type="email" margin="none" variant="outlined" required={true} />)
    },
  ]

  const onSubmit = async values => {
    setLoading(true);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    await sleep(500);
  
    const registerData = {
      ...values,
      username: values.collectivite+"#"+Math.floor((Math.random(100)*1000)) ,
      description: 'Collectivité: '+ values.collectivite + ' \n Fonction:' + (values.fonction?values.fonction:"Non renseigné")+"\n"+JSON.stringify(zone),
      type,
      function:values.fonction,
      title: values.collectivite,
      collectivite: null,
      registerFrom: "ADMIN"
    }

    console.log(registerData);
  
    let result = await new Promise(resolve => {
      authProvider.register(
        registerData,
        (success) => {
          // console.log("success:", success)
          resolve(false)
        },
        (error) => {
          if (error?.message && error?.message?.length && error?.message[0]?.messages&& error?.message[0]?.messages[0]?.id) {
            const message = error?.message[0]?.messages[0]?.id;
            switch (message) {
              case "Auth.form.error.email.taken":
                resolve({email: "Email déja enregistré"})
              break;
              case "Auth.form.error.username.taken":
                resolve({username: "Pseudonyme déja utilisé"})
              break;
              default:
                // console.log(error);
                resolve({error: "unknow-error"})
            }
          } else {
            resolve({error: "no-reponse"})
          }
        }
      );
    })
  
    console.log("onSubmit result:", result)

    if (!result) {
      // console.log("Form success")
      setSuccess(registerData)
    } else {
      // console.log("Form Error")
      if (result?.error) {
        setWarning("Inscription momentanément indisponible.")
      }
    }
    setLoading(false);
    return result;
  };

  const onChangeZone = (zoneObj) => {
    console.log("onChangeZone:", zoneObj);
    setZone(zoneObj);
  }

const styles = {
  paper: {
    padding:8, 
    cursor:"pointer",
    paddingTop:32,
    paddingBottom:24
  },
  divider: {
    height:3,
    marginTop:12, 
    marginBottom:12
  },
  dividerCol: {
    backgroundColor:"#50BCB8"
  },
  dividerAss: {
    backgroundColor:"#81C450"
  },
  center: {
    fontSize:18, 
    fontWeight:"bold",
    maxWidth: 200,
    margin:"auto"
  },
  centerCol: {
    color:"#50BCB8", 
  },
  centerAss: {
    color:"#81C450", 
  }
}

  return (
    <AuthLayout>
      <Typography component="h1" variant="h5">
        Inscription 
        {/* {((api?.site === "LAPADE")?"Collectivité":"Association")} */}
      </Typography>

      {warning && 
      <Box m={10}><MuiAlert severity="warning">
        <b>{warning}</b>
        </MuiAlert></Box>}

      {!type && <>
        <br />
        Vous êtes :
        <br /><br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper elevation={6} style={styles.paper} onClick={()=>setType("Collectivité")}>
              <center style={{...styles.center, ...styles.centerCol}}>
                <img alt="collectivite" src="/picto/collectivite.png" height={70} />
                <Divider style={{...styles.divider, ...styles.dividerCol}} />
                Collectivités<br />territoriales
                <Divider style={{...styles.divider, ...styles.dividerCol}}/>
              </center>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={6} style={styles.paper} onClick={()=>setType("Association")}>
              <center style={{...styles.center, ...styles.centerAss}}>
                <img alt="association" src="/picto/association.png" height={70} />
                <Divider style={{...styles.divider, ...styles.dividerAss}}/>
                Associations<br />environnementales
                <Divider style={{...styles.divider, ...styles.dividerAss}}/>
              </center>
            </Paper>
          </Grid>
        </Grid>
      </>}

      {type && 
      <>
      {!success && !warning && <Form
        onSubmit={onSubmit}
        initialValues={{
          gender:'Madame', 
          firstname:'', 
          name:'', 
          collectivite:'', 
          username:'', 
          email:'',
          password: '',
          passwordConfirm: ''
        }}
        validate={authValidate(validationSchema)}
        render={({ handleSubmit, submitting}) => {

          return (
            <form onSubmit={handleSubmit} noValidate className={classes.form} style={{opacity: loading?"0.5":"1" }}>
              <FormLabel >
                {(type==="Association")?"Association":"Localisation"}
              </FormLabel><br /><br />
                <Grid container alignItems="flex-start" spacing={2}>
                  {(type==="Association")?<></>:<ZoneSelector onChange={onChangeZone}/>}
                  {formFields.map((item, idx) => (
                    <Grid item {...item.size?item.size:{xs:12}} key={idx}>
                      {item.field}
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <TextField label="Mot de passe" name="password" type={togglePassword?"password":"text"} margin="none" variant="outlined" 
                      InputProps={{
                        endAdornment:(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={(e)=> setTogglePassword(!togglePassword)}
                              onMouseDown={(e)=> setTogglePassword(!togglePassword)}
                              edge="end"
                            >
                              {togglePassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                    }}/>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField label="Confirmation Mot de passe" name="passwordConfirm" type={togglePasswordConfirm?"password":"text"} margin="none" variant="outlined" 
                      InputProps={{
                        endAdornment:(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={(e)=> setTogglePasswordConfirm(!togglePasswordConfirm)}
                              onMouseDown={(e)=> setTogglePasswordConfirm(!togglePasswordConfirm)}
                              edge="end"
                            >
                              {togglePasswordConfirm ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                    )}} />
                  </Grid>
                  <Grid item xs={12}>
                    <SubLink/>
                  </Grid>
                  <Grid item xs={12} style={{textAlign:"center"}}>
                    <Button variant="contained" color="secondary" type="submit" disabled={submitting}>
                      Envoyer
                    </Button>
                  </Grid>
                </Grid>
                {loading && <div className={classes.loadingBox}><CircularProgress /></div>}
            </form>
          );
        }}
        />
      }
      </>
      }

      {success &&
        <Checkmark>
          <Box m={4}>
            <Paper>
              <Box p={4} pt={5}>
                <Typography>
                  <b>Inscription enregistré.</b><br />
                  Vous allez recevoir un email : {success.email}
                </Typography>
                <Typography>
                  Vous devez confirmer votre inscription en cliquant sur le lien contenu dans l'email.
                </Typography>
                <Button fullWidth href="#/login" variant="contained" color="primary" className={classes.submit}>
                  je me connecte
                </Button>
              </Box>
            </Paper>
          </Box>
        </Checkmark>
      }
    </AuthLayout>
  );
}

const SubLink = () => (
    <Grid container justifyContent="flex-end">
      <Grid item >
        <Link href="/#/login" variant="body2">
          Vous avez déja un compte ?
        </Link>
      </Grid>
    </Grid>
);